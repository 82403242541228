.MuiButtonBase-root.MuiTabScrollButton-root {
    color: rgb(0, 225, 255);
    box-shadow: 0 0 15px rgb(0, 0, 0);
    height: 34px;

}

.nav {
    height: 34px;
}

.css-1aquho2-MuiTabs-indicator {
    width: 0 !important;
    height: 0 !important;
}

.MuiTabs-indicator .css-ttwr4n{
    width: 0 !important;
    height: 0 !important;
}