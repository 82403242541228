.legend {
	background: rgba($white, .9);
	border: 1px solid $gray-300;
	z-index: 1010;
	& .legendLayer {
		& .background {
			fill: none;
		}
	}
}
.flot-text {
	& .tickLabel {
		color: $dark;
		font-size: rem(12px);
		font-weight: 600;
	}
}
.flot-tooltip {
	position: absolute;
	display: none;
	border: 0;
	padding: rem(6px) rem(10px);
	color: rgba($white, .9);
	font-weight: 600;
	font-size: rem(11px);
	background: rgba($dark-darker, .85);
	z-index: 10000 !important;
	
	@include border-radius($border-radius);
}