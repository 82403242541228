.navbar {
	&.navbar-sticky {
		top: $app-header-height + $app-content-padding-y;
		position: sticky;
		border-left: 1px solid $gray-400;
		padding: 0;
		
		@if $enable-rtl {
			padding-right: $spacer / 2;
			margin-right: $spacer / 2;
		} @else {
			padding-left: $spacer / 2;
			margin-left: $spacer / 2;
		}
		
		& .nav {
			width: 100%;
			position: relative;
			
			@include flex-direction-column();
			
			& .nav-link {
				color: $gray-600;
				padding: $nav-link-padding-y / 2 $nav-link-padding-x;
				
				&:hover {
					color: $gray-700;
				}
				&.active {
					color: $gray-900;
					font-weight: $font-weight-bolder;
				}
			}
		}
	}
}