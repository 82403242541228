
.pdf-controlls-container {
  height: 0;
  width: 0;
}

.sc-dJjYzT.wHzZy{
  z-index: 1;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.308);
  padding-left: 3rem;
  padding-right: 1rem;
  padding-top: 2rem;
 
  align-items: center;
  align-self: center;
  margin-right: 2rem;

}

.document-container {
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.308); 
}

div.pg-viewer-wrapper{
  z-index: 1;
 padding-top: 1rem;
 padding-bottom: 1rem;
 overflow: auto;
 padding-right: 1rem;

}

.pdf-viewer-container {
  z-index: 1;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.308);
  width: 700px;
  margin: auto
  
}


.iFrame {
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.308);
  margin: 1rem; 
  justify-self: center;
}

.buttonViewer {
  border-radius: 4px;
  margin: 1rem;
  width: 7rem !important;
  align-self: flex-end;
  padding: 0.5rem;
  font-size: 11pt;
  background-color:#2d353c;
  border: none;
  color: rgba(255, 255, 255, 0.767);
  font-weight: 600;
  
}

.buttonViewer:hover {
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.308);
}

.nonButton {
  display: none;
}

h1, h2, h3, h4, h5 {
  margin-top: 0 !important;
  margin-bottom: 1rem !important;
}

.wHzZy p {
  margin-bottom: 1rem !important;
}

@media screen and (max-width: 1450px) {
  .fileDisplay {
    display: none;
  }
}



