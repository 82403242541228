// ANIMATION - fade out
@include keyframes(appLoaderFadeOut) { from { opacity: 1.0; } to { opacity: 0; } }

.app-loader {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: $body-bg;
	z-index: $app-loader-zindex;
	height: 100vh;
	opacity: 1;
	
	@include display-flex();
	@include flex-align(center);
	@include flex-justify-content(center);
	
	&.fading {
		opacity: 0;
		
		@include animation(appLoaderFadeOut .2s ease);
	}
	&.loaded {
		display: none;
		
		& .spinner {
			@include animation(none);
		}
	}
}