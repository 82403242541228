.mailbox {
	height: 100%;
	border-top: 1px solid $gray-400;
	background: $light;
	
	@include display-flex();
	@include media-breakpoint-down(lg) {
		@include flex-direction-column();
	}
	@include media-breakpoint-down(md) {
		min-height: calc(100vh - #{$app-header-height * 2 + rem(1px)});
	}
	
	& .mailbox-sidebar {
		@include media-breakpoint-up(lg) {
			width: rem(200px);
		
			@include display-flex();
			@include flex-direction-column();
			@include flex(0 0 200px);
		}
		& .mailbox-sidebar-header {
			padding: $spacer;
			border-bottom: 1px solid $gray-400;
		}
		& .mailbox-sidebar-content {
			@include flex(1);
			
			@include media-breakpoint-up(lg) {
				overflow: hidden;
			}
			@include media-breakpoint-down(lg) {
				border-bottom: 1px solid $gray-400;
			}
		}
	}
	& .mailbox-content {
		@include flex(1);
		@include display-flex();
		@include flex-direction-column();
		@include media-breakpoint-up(lg) {
			max-width: calc(100% - 200px);
			
			@if $enable-rtl {
				border-right: 1px solid $gray-400;
			} @else {
				border-left: 1px solid $gray-400;
			}
		}
		
		& .mailbox-content-header {
			padding: $spacer;
			border-bottom: 1px solid $gray-400;
		}
		& .mailbox-content-body {
			background: $white;
			
			@include flex(1);
			@include media-breakpoint-up(md) {
				overflow: hidden;
			}
			
			& .mailbox-float-link {
				padding: $spacer / 2 $spacer;
	
				@include display-flex();
	
				& a {
					color: $gray-600;
					text-decoration: none;
					font-weight: $font-weight-bold;
		
					&:hover,
					&:focus {
						color: $gray-800;
					}
				}
			}
			& .mailbox-to {
				position: relative;
				border-bottom: 1px solid $border-color;
				padding: $spacer / 1.8 $spacer;
				min-height: rem(46px);
	
				@include display-flex();
				@include flex-align(flex-start);
	
				& .tagit {
					border-color: transparent !important;
		
					@include border-radius(0);
					@include flex(1);
				}
				& .form-control {
					border-color: transparent !important;
					padding-left: $spacer !important;
					padding-right: $spacer !important;
		
					@include border-radius(0);
		
					&:focus {
						@include box-shadow(none);
					}
				}
				& .control-label {
					width: rem(60px);
					padding: $spacer / 2 $spacer;
					margin-bottom: 0;
					font-weight: $form-label-font-weight;
					background: $blue-100;
					color: $blue-600;
					text-align: center;
					min-height: $input-height;
				}
			}
			& .mailbox-subject {
				padding: $spacer / 1.8 0;
				border-bottom: 1px solid $border-color;
	
				& .form-control {
					border-color: transparent !important;
					padding-left: $spacer !important;
					padding-right: $spacer !important;
		
					@include border-radius(0);
		
					&:focus {
						@include box-shadow(none);
					}
				}
			}
			& .mailbox-form {	
				min-height: 100%;
				
				@include display-flex();
				@include flex-direction-column();
			}
			& .mailbox-input {
				padding: 0;
				margin: 0;
				
				@include flex(1);
				@include display-flex();
				@include flex-direction-column();
		
				& .wysihtml5-toolbar {
					background: $white !important;
					border: none !important;
					padding-bottom: 0 !important;
				}
				& .ql-snow.ql-toolbar,
				& .note-editor .note-toolbar {
					background: $light !important;
					border-color: $border-color !important;
				}
				& .note-editor {
					height: 100%;
					min-height: rem(100px);
					
					@include display-flex();
					@include flex-direction-column();
					@include flex(1);
					
					& .note-toolbar {
						padding: rem(6px) $spacer !important;
					
						& .note-btn-group {
							margin: 0 !important;
						}
						& .note-btn {
							background: none;
							border: none;
						
							&:hover,
							&.active {
								background: $gray-300;
							
								@include border-radius($border-radius !important);
								@include box-shadow(none !important);
							}
						}
					}
					& .note-editing-area {
						@include display-flex();
						@include flex-direction-column();
						@include flex(1);
						
						& .note-editable {
							height: 100% !important;
							
							@include flex(1);
						}
					}
				}
				
				& .ql-container {
					@include display-flex();
					@include flex-direction-column();
				}
				& .ql-editor,
				& .ql-container,
				& .wysihtml5-sandbox {
					@include flex(1);
				}
				& .wysihtml5-sandbox {
					padding: $spacer !important;
					border: none !important;
					outline: none !important;
			
					@include border-radius(0 !important);
				}
			}
		}
		& .mailbox-content-footer {
			padding: $spacer;
			border-top: 1px solid $gray-400;
		}
	}
	& .nav-title {
		font-size: rem(11px);
		color: lighten($dark, 45%);
		padding: rem(15px) rem(15px) rem(7px);
	}
	& .nav-inbox {
		list-style-type: none;
		margin: 0;
		padding: 0;
		display: block;
		
		& > li {
			display: block;
			
			& + li {
				margin-top: 0;
			}
			& > a {
				text-decoration: none;
				border-radius: 0;
				padding: $spacer / 2 $spacer;
				color: $gray-800;
				font-weight: $font-weight-bold;
				
				@include display-flex();
				@include flex-align(center);
				
				& i {
					width: rem(24px);
				}
				&:hover {
					color: $gray-600;
				}
			}
			&.active {
				& > a {
					background: $gray-300;
					color: $gray-800;
				}
			}
		}
	}
	& .attached-document {
		list-style-type: none;
		margin: rem(15px) 0 0;
		padding: 0;
		
		@include display-flex();
		@include flex-wrap(wrap);
		
		& > li {
			width: rem(180px);
			background: $white;
			color: $dark;
			font-weight: 600;
			position: relative;
			margin-right: rem(15px);
			margin-bottom: rem(15px);
			border: 1px solid $border-color;
			
			@if $enable-rtl {
				margin-right: 0;
				margin-left: rem(15px);
			}
			
			&:before {
				position: absolute;
				top: rem(-1px);
				right: rem(-1px);
				font-size: rem(12px);
				background: lighten($dark, 65%);
				width: rem(20px);
				height: rem(20px);
				line-height: rem(20px);
				text-align: center;
				
				@include fontawesome();
			}
			& img {
				max-width: 100%;
			}
			& .document-name {
				padding: rem(5px) rem(10px);
				text-align: center;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				background: $light;
				border-top: 1px solid $border-color;
				
				& a {
					color: $dark;
				}
			}
			& .document-file {
				height: rem(70px);
				background: none;
				overflow: hidden;
				text-align: center;
				line-height: rem(70px);
				font-size: rem(32px);
				margin: rem(-1px);
			}
		}
	}
	& .list-email {
		border-top: 1px solid lighten($border-color, 5%);
		border-bottom: 1px solid lighten($border-color, 5%);
		margin: -1px 0;
		padding: 0;
		
		& > li {
			&.list-group-item {
				border: none;
				margin-bottom: 0;
				padding: rem(15px);
				position: relative;
				width: 100%;
				overflow: hidden;
				
				@include border-radius(0);
				@include clearfix();
				@include display-flex();
				@include flex-align(center);
				
				& + li {
					&.list-group-item {
						border-top: 1px solid lighten($border-color, 5%);
					}
				}
				
				& a {
					color: $dark;
					text-decoration: none;
				}
				& .email-info {
					max-width: calc(100% - 46px);
					
					@include flex(1);
					
					& > a {
						margin-top: rem(-15px);
						margin-right: rem(-15px);
						margin-bottom: rem(-15px);
						padding: rem(15px);
						max-width: 100%;
						
						@include display-flex();
						@include flex-wrap(wrap);
						@if $enable-rtl {
							margin-right: 0;
							margin-left: rem(-15px);
						}
						@include media-breakpoint-down(sm) {
							display: block;
						}
					}
				}
				& .email-user {
					width: rem(30px);
					height: rem(30px);
					min-width: rem(30px);
					overflow: hidden;
					font-size: rem(18px);
					line-height: rem(30px);
					text-align: center;
					color: lighten($dark, 30%);
					background: lighten($dark, 60%);
					margin: rem(-5px) 0;
					
					@include border-radius(30px);
					
					& img {
						max-width: 100%;
						display: block;
					}
				}
				& .email-sender {
					display: block;
					width: rem(200px);
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					
					@include media-breakpoint-down(lg) {
						width: rem(160px);
					}
					@include media-breakpoint-down(md) {
						width: rem(120px);
					}
					@include media-breakpoint-down(sm) {
						width: auto;
						font-size: rem(14px);
						padding-right: rem(90px);
						
						@if $enable-rtl {
							padding-right: rem(15px);
							padding-left: rem(90px);
						}
					}
				}
				& .email-title {
					display: block;
					line-height: rem(20px);
					width: rem(300px);
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					
					@include media-breakpoint-up(xl) {
						@if $enable-rtl {
							padding-right: rem(15px);
						} @else {
							padding-left: rem(15px);
						}
					}
					@include media-breakpoint-down(lg) {
						width: auto;
						
						@include flex(1);
					}
					
					& + .email-desc {
						@include media-breakpoint-up(md) {
							padding-left: rem(20px);
							
							@if $enable-rtl {
								padding-left: 0;
								padding-right: rem(20px);
							}
							&:before {
								content: '';
								position: absolute;
								left: rem(6px);
								top: rem(8px);
								width: rem(4px);
								height: rem(4px);
								background: lighten($dark, 45%);
								@include border-radius($border-radius-lg);
								@if $enable-rtl {
									left: auto;
									right: rem(6px);
								}
							}
						}
					}
				}
				& .email-desc {
					display: block;
					position: relative;
					color: lighten($dark, 15%);
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					
					@include flex(1);
					@include media-breakpoint-down(lg) {
						display: none;
					}
					@include media-breakpoint-down(sm) {
						display: block;
						padding-right: 0;
					}
				}
				& .email-time {
					display: block;
					padding: 0 rem(15px) 0 0;
					font-size: rem(11px);
					font-weight: 500;
					color: lighten($dark, 30%);
					text-align: right;
					width: rem(100px);
					position: absolute;
					right: 0;
					top: rem(15px);
					height: rem(20px);
					background: $white;
					
					@include display-flex();
					@include flex-align(center);
					@include flex-justify-content(flex-end);
					@if $enable-rtl {
						padding-right: 0;
						padding-left: rem(15px);
						right: auto;
						left: 0;
						text-align: left;
					}
					@include media-breakpoint-down(lg) {
						width: rem(90px);
					}
				}
				& .email-checkbox {
					& .custom-checkbox {
						width: rem(16px);
						padding-left: rem(16px);
						
						@if $enable-rtl {
							padding-left: 0;
							padding-right: rem(16px);
						}
						
						& .custom-control-label {
							&::before,
							&::after {
								left: rem(-16px);
								
								@if $enable-rtl {
									left: auto;
									right: rem(-16px);
								}
							}
						}
					}
					& + .email-user {
						margin-left: rem(10px);
						
						@if $enable-rtl {
							margin-left: 0;
							margin-right: rem(10px);
						}
					} 
				}
				
				&.unread {
					& .email-title {
						font-weight: 600;
						color: $dark;
					}
					& .email-sender {
						font-weight: 600;
					}
				}
				&.selected {
					background: lighten($yellow, 46%);
					
					& .email-time {
						background: lighten($yellow, 46%);
					}
				}
			}
		}
	}
}