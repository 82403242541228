
div.tab-pane.fade.active.show{
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    background-color: white;
   
}

ul.listen{
    background: white !important;
    padding: 1rem;
    margin-bottom: 1rem;
}

i {
    margin-right: 1rem;
}

i.fas.fa-plus {
    color: rgb(0, 225, 255);
    text-shadow: 1px 1px 2px #000000b0;
}

i.leer {
    margin-right: 1.5rem;
}

i.fas.fa-folder{
  
    color: rgb(0, 225, 255); 
    text-shadow: 1px 1px 2px #000000b0;
}

i.far.fa-folder{
    text-shadow: 1px 1px 2px #000000b0;
}


i.fas.fa-folder-open{
    color: rgb(0, 225, 255);
    text-shadow: 1px 1px 2px #000000b0;
}


i.fas.fa-file{
    color: rgb(61, 61, 61);
    text-shadow: 1px 1px 2px #000000b0;
}


i.fas.fa-angle-down {
   
    color: rgb(0, 225, 255); 
}

.checkFormularButton{
  border-radius: 4px;
  width: 9rem !important;
  text-align: center;
  padding: 0.5rem;
  font-size: 11pt;
  background-color:#ffffff;
  border: none;
  color: rgb(0, 0, 0);
  font-weight: 600;
  border: 1px solid silver;
}

.checkFormularButton:hover{
    background-color: rgba(0, 0, 0, 0.075);
}

.listen > li {
   font-size: 1rem;
   padding-bottom: .5rem;
   padding-top: .5rem;
   text-decoration: none ;
   text-decoration: none;
   list-style: none;
}

.folderItem {
    text-decoration: none;
    color: black;
}

.fileItem {
    text-decoration: none;
    color: black;
}

.fileItem:hover{
    color: black;
}

.folderItem:hover{
    color: black;
}


.listen{
    height: max-content;
    padding-left: 4rem !important;
    border-left: 2px solid rgba(128, 128, 128, 0.418);
}

.liste0{
    padding-left: 1rem !important;
    border-left: none;

}


.fileDisplay {
    flex-direction: column;
    display: flex;
 
    margin-bottom: 1rem;
   
}