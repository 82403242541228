.popUp {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
  z-index: 999;
}

.popUpOuter {
  width: 40%;
  position: absolute;
  left: 30vw;
  top: 30vh;
  background: white;
  padding: 30px 0px;
  border-radius: 4px;
}

.popUpInner {
  display: grid;
  grid-template-columns: auto;
  row-gap: 20px;
  margin: auto;
  justify-content: center;
  align-items: center;
  width: 60%;
  /* margin-bottom: calc(1.25em + 30px); */
}

.popUp h1 {
  text-align: center;
}

.closeButton{
  width: max-content;
  justify-self: end;
  border-radius: 4px;
  text-align: center;
  background-color:#ffffff;
  border: none;
  color: silver;
  transition: 0.2s;
  position: absolute;
  top: 3px;
  right: 3px;
}

.closeButton:hover{
  color: black;
}

.input-container{
  display: grid;
  grid-template-columns: auto 1fr;
  float: left;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.inputNewName{
  width: 100%;
  border: 1px solid silver;
  border-radius: 4px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 12px;
  padding-right: 12px;
}

.fas.fa-times{
  margin-right: 0;
  padding: 5px;
}

label{
  font-size: 15px;
}

.submit-button{
  border-radius: 4px;
  width: 9rem !important;
  text-align: center;
  padding: 2px;
  font-size: 15px;
  background-color:#ffffff;
  border: none;
  color: rgb(0, 0, 0);
  border: 1px solid silver;
  /*position: absolute;
  right: 5px;
  bottom: 5px;*/
  justify-self: center;
  margin-top: 30px;
}
  