.nav {
	&.nav-tabs {
		&.nav-tabs-inverse {
			background-color: $gray-900;
			
			@include border-radius($border-radius $border-radius 0 0);
			
			& .nav-item {
				& .nav-link {
					color: rgba($white, .65);
					
					&:hover,
					&:focus {
						color: $white;
					}
					&.active {
						color: $dark;
					}
				}
			}
		}
	}
	&.nav-pills {
		& .nav-item {
			& .nav-link {
				padding: $spacer * 0.66 $spacer;
				font-weight: $font-weight-bold;
				color: lighten($dark, 30%);
				
				&:hover,
				&:focus {
					color: $dark;
				}
				&.active {
					color: $white;
				}
			}
		}
	}
}