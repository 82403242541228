@mixin background-gradient($className, $from, $to) {
	.#{$className},
	.#{$className}-to-right,
	.#{$className}-diagonal-down,
	.#{$className}-diagonal-up {
		background: $from;
	}
	.#{$className} {
		background: -moz-linear-gradient(to bottom, $from 0%, $to 100%) !important;
		background: -webkit-linear-gradient(to bottom, $from 0%, $to 100%) !important;
		background: linear-gradient(to bottom, $from 0%, $to 100%) !important;
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=0 ) !important;
	}
	.#{$className}-to-right {
		background: -moz-linear-gradient(to right, $from 0%, $to 100%) !important;
		background: -webkit-linear-gradient(to right, $from 0%, $to 100%) !important;
		background: linear-gradient(to right, $from 0%, $to 100%) !important;
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=1 ) !important;
	}
	.#{$className}-to-top {
		background: -moz-linear-gradient(to top, $from 0%, $to 100%) !important;
		background: -webkit-linear-gradient(to top, $from 0%, $to 100%) !important;
		background: linear-gradient(to top, $from 0%, $to 100%) !important;
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=1 ) !important;
	}
	.#{$className}-to-left {
		background: -moz-linear-gradient(to left, $from 0%, $to 100%) !important;
		background: -webkit-linear-gradient(to left, $from 0%, $to 100%) !important;
		background: linear-gradient(to left, $from 0%, $to 100%) !important;
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=1 ) !important;
	}
	.#{$className}-diagonal-down {
		background: -moz-linear-gradient(-45deg, $from 0%, $to 100%) !important;
		background: -webkit-linear-gradient(-45deg, $from 0%, $to 100%) !important;
		background: linear-gradient(-45deg, $from 0%, $to 100%) !important;
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=1 ) !important;
	}
	.#{$className}-diagonal-up {
		background: -moz-linear-gradient(45deg, $from 0%, $to 100%) !important;
		background: -webkit-linear-gradient(45deg, $from 0%, $to 100%) !important;
		background: linear-gradient(45deg, $from 0%, $to 100%) !important;
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=1 ) !important;
	}
}