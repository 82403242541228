.toast-container {
	position: fixed;
	top: $app-header-height;
	margin-top: $spacer;
	right: $spacer;
	z-index: $app-content-zindex;
}
.toast {
	border-width: $toast-border-width;
	
	& .toast-header {
		border-bottom-width: $toast-header-border-width;
	}
}